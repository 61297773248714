<script setup lang="ts">

import Sidebar from "@/layout/Sidebar.vue"
import useVersion from "@/composables/useVersion";
import {onMounted, watch} from "vue";
import {useRoute} from "vue-router";
import RefreshBanner from "@/layout/RefreshBanner.vue";

const route = useRoute()
const {initVersion, checkVersion} = useVersion()

onMounted(async () => {
  await initVersion()
  window.addEventListener('focus', () => checkVersion())
})

watch(() => route.path, async () => {
  await checkVersion()
})

</script>

<template>
  <div class="min-h-screen flex flex-col bg-surface-ground">
    <div class="flex relative lg:static  flex-auto">
        <Sidebar />

      <div class="flex flex-col flex-auto lg:pl-8">
        <RefreshBanner/>
        <div class="bg-surface-ground flex-auto">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

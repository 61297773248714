<script setup lang="ts">

import AppPage from "@/components/AppPage.vue";
import {onMounted, ref} from "vue";
import {peopleBackend, Person} from "@/backend/people.backend.ts";
import Debug from "@/components/Debug.vue";
import HOSAvatar from "@/components/hOSAvatar.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {humanDateMMMddyyyy} from "@/helpers/date.helper";
import InlineProfile from "@/components/user/InlineProfile.vue";
import {FilterMatchMode} from "@primevue/core/api";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";


const people = ref<Person[]>([]);
const expandedRows = ref<any[]>([]);
const filters = ref({global: {value: null, matchMode: FilterMatchMode.CONTAINS}})

onMounted(async () => {
  people.value = await peopleBackend.getAll();
});

</script>

<template>
  <AppPage title="hOS Team" :loaded="true">
    <DataTable
        :value="people" selection-mode="single"
        :expanded-rows="expandedRows" data-key="id"
        :globalFilterFields="['name', 'department.name', 'meta.location', 'title']" v-model:filters="filters"
    >
      <template #header>
        <div class="flex flex-row gap-2 items-center">
          <IconField>
            <InputIcon><i class="pi pi-search"/></InputIcon>
            <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
          </IconField>
          <span class="text-muted-color text-sm">Press Cmd+K anywhere in the app</span>
        </div>
      </template>
      <Column style="width: 1rem" expander/>
      <Column style="width: 1rem">
        <template #body="{data}">
          <h-o-s-avatar :user="data"/>
        </template>
      </Column>
      <Column field="name" header="Name" :sortable="true"/>
      <Column field="department.name" header="Function" :sortable="true"/>
      <Column field="title" header="Title" :sortable="true"/>
      <Column field="meta.location" header="Location" :sortable="true"/>
      <Column header="Start date" :sortable="true" field="meta.startDate">
        <template #body="{data}">
          {{ humanDateMMMddyyyy(data.meta.startDate) }}
        </template>
      </Column>
      <template #expansion="{data}">
        <InlineProfile :user="data" />
      </template>
    </DataTable>
    <Debug :data="people"/>
  </AppPage>
</template>

import {request} from "@/backend/backend_util.ts";

export type Person = {
    id: number;
    name: string;
    email: string;
    username: string;
}

export const peopleBackend = {
    getAll: request<Person[]>('GET', '/people'),
    departments: request('GET', '/people/departments'),
}


<script setup lang="ts">
import ConfirmDialog from "primevue/confirmdialog";
import PeopleSelector from "@/components/PeopleSelector.vue";
import ButtonGroup from "primevue/buttongroup";
import Button from "primevue/button";
import {computed, ref} from "vue";
import {useConfirm} from "primevue/useconfirm";
import {backend} from "@/backend/backend";
import {useAuthStore} from "@/stores";
import useAppToast from "@/composables/useAppToast";
import {useRouter} from "vue-router";

const router = useRouter();
const {user} = defineProps(['user'])
const emit = defineEmits(['update:user'])
const users = ref(await backend.users());

function saveUser() {
  emit('update:user')
}

const isDev = computed(() => {
  return process.env.NODE_ENV !== 'production';
})

function addPeople(people: any[]) {
  user.people.push(...people);
  saveUser();
}

function removePeople(people: any[]) {
  user.people = user.people.filter((p: any) => !people.includes(p));
  saveUser();
}

const confirm = useConfirm();
const toaster = useAppToast()

function deleteUser() {
  confirm.require({
    message: `Are you sure you want to deactivate ${user.name}? All their data will be kept.`,
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    acceptClass: 'p-button-danger',
    rejectClass: 'p-button-secondary p-button-outlined',
    accept: deleteConfirmed,
  });
}

async function deleteConfirmed() {
  try {
    const userId: number = user.id;
    if (userId) {
      // @ts-ignore
      await backend.deleteUser({id: userId});
      toaster.success("Deactivated user " + user.name);
    }
    router.push("/people")
  } catch (e) {
    console.log(e);
    toaster.error(e);
  }
}

function devImpersonate() {
  if (isDev) {
    useAuthStore().silentLogout();
    // @ts-ignore
    window.location = import.meta.env.VITE_API_URL + "/api/auth/impersonate/?username=" + user.username;
  }
}

</script>

<template>
  <div>
    <ConfirmDialog></ConfirmDialog>
    <div class="">
      <label for="roles">Roles</label>
      <div class="btn-group" role="group">
        <ButtonGroup>
          <Button label="leader" :outlined="!user.is_leader" @click="user.is_leader = !user.is_leader; saveUser()"/>
          <Button label="reviewer" :outlined="!user.is_reviewer"
                  @click="user.is_reviewer = !user.is_reviewer; saveUser()"/>
          <Button label="admin" :outlined="!user.is_admin" @click="user.is_admin = !user.is_admin; saveUser()"/>
        </ButtonGroup>
      </div>
    </div>
    <div class="field col-span-12" v-if="user.is_leader">
      <PeopleSelector :people="user.people" class="container gy-3"
                      @people:add="addPeople"
                      @people:remove="removePeople"
                      :users="users"/>
    </div>
    <div class="field col-span-12">
      <div class="flex justify-end">
        <Button severity="warn" text @click="devImpersonate" v-if="isDev">Impersonate</Button>

        <Button severity="danger" class="float-end" @click="deleteUser">Deactivate</Button>
      </div>
    </div>
  </div>


</template>

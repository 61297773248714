<style scoped>
.footer-item {
  @apply cursor-pointer inline-flex items-center justify-center
  border-2 border-surface rounded-full
  hover:bg-surface-100 dark:hover:bg-surface-700
  text-surface-600 dark:text-surface-200
  transition-colors duration-150 w-[40px] h-[40px]
}
</style>
<script setup>

import AppSubmenuItem from "@/layout/AppSubmenuItem.vue";
import AppMenuItem from "@/layout/AppMenuItem.vue";

import {useAuthStore} from "@/stores";
import hOSAvatar from "@/components/hOSAvatar.vue";
import {isDark, toggleDark} from "@/composables/useDark";
import HideSidebarButton from "@/layout/HideSidebarButton.vue";
import {ref} from "vue";

const authStore = useAuthStore();
const appSidebar = ref()

</script>

<template>
  <!--  <span class="hidden" id="app-sidebar">Hello</span>-->
  <div id="app-sidebar" v-if="authStore.user" ref="appSidebar"
       class="bg-surface border border-surface hidden lg:block shrink-0 absolute select-none z-50 h-screen lg:sticky lg:top-0 lg:left-0 group">
    <HideSidebarButton />
    <div class="flex flex-col h-full w-[200px]" id="app-sidebar-content">
      <div class="overflow-y-auto" >
        <ul class="list-none pb-4 pt-4 m-0">
          <li>
            <ul class="list-none p-0 m-0 overflow-hidden">
              <li class="flex justify-center mb-2">
                <RouterLink to="/">
                  <img src="/hos-logo-80x80.png" alt="Image" class="border-1 rounded-full"/>
                </RouterLink>
              </li>
              <li>
                <AppMenuItem to="/" icon="pi-home" label="Home" :exact="true"/>
              </li>
              <li>
                <AppMenuItem to="/pulse" icon="pi-wave-pulse" label="Pulse" v-if="authStore.user.pulse_enabled"/>
              </li>
              <li>
                <AppMenuItem to="/pulse/hos" icon="pi-wave-pulse" label="hOS Pulse"
                             v-if="!authStore.user.pulse_enabled && authStore.isAdmin"/>
              </li>
              <li>
                <AppMenuItem to="/checkin/" icon="pi-bolt" label="Weekly Check-in"/>
              </li>
              <li>
                <AppMenuItem to="/retro" icon="pi-trophy" label="People Retro" v-if="authStore.isReviewer"/>
              </li>
              <li>
                <AppMenuItem to="/time-off" icon="pi-calendar" label="Time Off"/>
              </li>
              <li>
                <AppMenuItem to="/sks" icon="pi-heart" label="SKS Feedback"/>
              </li>
              <li>
                <AppMenuItem to="/people" icon="pi-users" label="hOS People"/>
              </li>
              <li>
                <AppMenuItem href="https://docs.google.com/forms/d/1hdvQ4BBVyxd9vwNwlwYGU6IlogiewoLtz-X43FY8tOw/viewform"
                             icon="pi-trophy" label="Shout-out!" :exact="true">
                  <i class="pi pi-external-link ml-2 !text-xs"></i>
                </AppMenuItem>
              </li>

              <li v-if="authStore.isAdmin">
                <AppSubmenuItem icon="pi-star" label="Admin">
                  <AppMenuItem to="/admin/users" icon="pi-users" label="Users"/>
                  <AppMenuItem to="/admin/groups" icon="pi-table" label="Groups"/>
                  <AppMenuItem to="/admin/badges" icon="pi-verified" label="Badges"/>
                  <AppMenuItem to="/admin/config" icon="pi-cog" label="Config"/>
                </AppSubmenuItem>
              </li>

            </ul>
          </li>
        </ul>
      </div>
      <div class="mt-auto border-t border-surface p-4 flex justify-between items-center">
        <a v-ripple class="footer-item" @click="toggleDark">
          <i class="pi text-xl" :class="isDark() ? 'pi-moon' : 'pi-sun'"></i>
        </a>
        <RouterLink to="/profile">
          <hOSAvatar :user="authStore.user" class="!w-[50px] !h-[50px]"/>
        </RouterLink>
        <a v-ripple @click="authStore.logout"
           class="footer-item !border-red-400 hover:!bg-red-100 !text-red-500 dark:!border-red-900 dark:hover:!bg-red-900 dark:text-red-100">
          <i class="pi pi-sign-out text-xl"></i>
        </a>
      </div>
    </div>
  </div>
</template>

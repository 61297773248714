<script setup>
import CheckinCard from '../components/CheckinCard.vue'
import {backend, CheckinHelper} from "@/helpers";
import Button from "primevue/button";
import CheckinHints from "@/components/CheckinHints.vue";
import Weekdays from "@/components/Weekdays.vue";
import Panel from "primevue/panel";
import PulseWeekToggle from "@/components/pulse/PulseWeekToggle.vue";
import PulseWeek from "@/components/pulse/PulseWeek.vue";
import {computed, onMounted, ref} from "vue";
import useAppToast from "@/composables/useAppToast";
import {useAuthStore} from "@/stores";
import Skeleton from "primevue/skeleton";
import RetroWeekPicker from "@/components/retro/RetroWeekPicker.vue";
import {todayWeek} from "@/composables/useRetroWeek";

const toaster = useAppToast();
const authStore = useAuthStore();

const person = ref("self");
const checkin = ref();
const editing = ref(false);
const saving = ref(false);
const loaded = ref(false);
const selectedWeek = ref(await todayWeek());

const hasDimensions = computed(() => {
  return CheckinHelper.hasDimensions(checkin.value);
});

const editable = computed(() => {
  return !checkin.value.missed && !checkin.value.acknowledgedById;
});

const init = async () => {
  loaded.value = false;
  checkin.value = await backend.getSelfCheckin(selectedWeek.value.toISODate());
  editing.value = checkin.value?.id === null;
  loaded.value = true;
};

onMounted(async () => {
  await init();
})

const saveCheckin = async () => {
  try {
    saving.value = true;
    checkin.value = await backend.saveSelfCheckin(checkin.value);
    toaster.success("Saved!", "Thanks for your checkin!");
    editing.value = false;
  } catch (e) {
    console.log("Caught error", e);
    toaster.error("Ooos :(", "Couldn't save your checkin");
  }
  saving.value = false;
};

const cancelEdit = async () => {
  editing.value = false;
  await init();
};


</script>

<template>
  <div v-if="loaded">
    <div class="flex flex-col w-full self-center items-center">
      <div class="flex flex-col md:flex-row gap-2 content-center justify-between mb-4 w-full xl:w-[60rem] ">
        <div class="flex gap-2">
          <RetroWeekPicker v-model="selectedWeek" @change="init" />
        </div>
        <div class="flex justify-end items-center" v-if="checkin">
          <Weekdays :weekdays="checkin.weekdays"/>
          <PulseWeekToggle selector="pulse" :enabled="authStore.user.pulse_enabled"/>
        </div>
      </div>
      <PulseWeek :pulses="checkin.weekdays" :editable="true" id="pulse" class="hidden mb-2 w-full"
                 v-if="authStore.user.pulse_enabled"/>


      <div v-if="checkin.missed">
        <h3>You didn't submit a checkin for this week.</h3>
      </div>
      <div v-else class="w-full xl:w-[60rem] ">
        <div class="mb-2">
          <Panel>
            <CheckinCard :entry="checkin" :self="true" :readonly="!editing">
              <template #left>
                <Button label="Edit" icon="pi pi-pencil" severity="primary" outlined
                        v-if="editable && !editing"
                        @click="editing = true"/>
              </template>
            </CheckinCard>
            <div v-if="editing">
              <CheckinHints class="my-2"/>
              <div class="flex justify-end gap-2">
                <Button label="Cancel" severity="secondary" v-if="checkin.id" @click="cancelEdit"/>
                <Button label="Save" severity="primary" @submit.prevent :disabled="saving || !hasDimensions"
                        @click="saveCheckin"/>
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Skeleton />
  </div>

</template>

<style>
</style>

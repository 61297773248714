<script setup lang="ts">

import AppPage from "@/components/AppPage.vue";
import RouteTab from "@/components/RouteTab.vue";
import SelfCheckinView from "@/views/SelfCheckinView.vue";
import LeadCheckinView from "@/views/LeadCheckinView.vue";
import {useAuthStore} from "@/stores";
import {onMounted} from "vue";
import {useRouter} from "vue-router";
const SELF_ROUTE = "/checkin/self"
const LEAD_ROUTE = "/checkin/lead";
const authStore = useAuthStore();
const router = useRouter();


const tabs = authStore.isLeader ? [{
  label: "Self",
  to: SELF_ROUTE,
  icon: 'pi pi-user'
}, {
  label: "Lead",
  to: LEAD_ROUTE,
  icon: 'pi pi-flag'
}] : []
onMounted(() => {

  if (![SELF_ROUTE, LEAD_ROUTE].includes(router.currentRoute.value.path)) {
    router.push(SELF_ROUTE)
  }
})
</script>

<template>
<AppPage title="Weekly Check-in" :loaded="true" :tabs="tabs">
  <RouteTab :tab-route="SELF_ROUTE">
    <SelfCheckinView />
  </RouteTab>
  <RouteTab :tab-route="LEAD_ROUTE">
    <LeadCheckinView />
  </RouteTab>
</AppPage>
</template>

<script setup lang="ts">
import {formatDate} from "@/helpers/date.helper";
import {debounce} from "throttle-debounce";

import {computed, onMounted, ref, watch} from "vue";
import pulseBackend from "@/backend/pulse.backend";
import SmartText from "@/components/SmartText.vue";
import useAppToast from "@/composables/useAppToast";
import TimeoffType from "@/components/timeoff/TimeoffType.vue";
import PulseComments from "@/components/pulse/PulseComments.vue";
import PulseCommenter from "@/components/pulse/PulseCommenter.vue";

const {pulse, editable} = defineProps(['pulse', 'editable'])
const storageId = computed(() => `pulse-${pulse.date}`)

const toast = useAppToast()
const decoration = computed(() => {
  if (!pulse || pulse.editMode)
    return ''
  switch (pulse.type) {
    case 'PULSE':
      return '!bg-green-50 dark:!bg-green-900';
    case 'NO_INFO':
      return '!bg-amber-50 !text-amber-600 dark:!bg-amber-900 dark:!text-amber-50'
    case 'OOO':
      return '!bg-surface-50 !text-muted-color dark:!bg-surface-600'
    case 'FUTURE':
    case 'TODAY':
    default:
      return ''
  }

})

onMounted(() => {
  if (pulse && pulse.type === 'TODAY' && editable) {
    pulse.editMode = true
    pulse.newText = getLocal()
  }
})

const canEdit = computed(() => {
  return pulse && editable && ['PULSE', 'NO_INFO', 'TODAY'].includes(pulse.type)
})

function resetNewText() {
  const localText = getLocal();
  if (localText) {
    return localText
  }
  return pulse.type == 'PULSE' ? pulse.text : ''
}

const saving = ref(false)

function edit() {
  pulse.editMode = true
  pulse.newText = resetNewText()
}

watch(() => pulse.newText, () => {
  debounceStoreLocal()
})

const debounceStoreLocal = debounce(500, storeLocal)

function storeLocal() {
  localStorage.setItem(storageId.value, pulse.newText)
}

function resetLocal() {
  localStorage.removeItem(storageId.value)
}

function getLocal() {
  return localStorage.getItem(storageId.value)
}

async function save() {
  saving.value = true;
  pulse.text = pulse.newText
  const saved = await pulseBackend.post(pulse)
  toast.success('Saved')
  pulse.id = saved.id
  pulse.type = saved.type
  pulse.editMode = false
  localStorage.removeItem(storageId.value)
  saving.value = false;
}

function cancel() {
  resetLocal()
  pulse.newText = resetNewText()
  pulse.editMode = false
}

const pulseCommenter = ref()
function showCommentPopover(e: any) {
  pulseCommenter.value.show(e, pulse)
}

</script>

<template>

  <Fieldset class="mt-8  group" :class="decoration"
            pt:legend:class="!border !border-surface-200 dark:!border-surface-700"
            pt:root:class="!px-2"
  >
    <template #legend>
      <div class="flex flex-row items-center" :id="pulse.id ? `legend-${pulse.id}` : ''">
        <slot name="legend">
          <div class="flex flex-row items-center ">
            <span class="font-bold">{{ formatDate(pulse.date, 'EEE, MMM dd') }}</span>
          </div>
        </slot>
        <i class="md:hidden md:group-hover:inline pi pi-pencil cursor-pointer hover:bg-surface-50 ml-2 p-1 rounded-md text-muted-color"
           v-if="canEdit && !pulse.editMode"
           @click="edit"
        />
      </div>

    </template>

    <div v-if="pulse">
      <PulseCommenter ref="pulseCommenter"/>
      <div class="p-2" v-if="!pulse.editMode">
        <div v-if="pulse.type === 'PULSE'">
          <SmartText :text="pulse.text" @click="showCommentPopover"/>
          <PulseComments :pulse="pulse" v-if="pulse.commentCount" class="pt-2"/>
        </div>
        <TimeoffType v-else-if="pulse.type === 'OOO'" :value="pulse.text"/>
        <span v-else>{{ pulse.text }}</span>
      </div>
      <div v-else class="pt-2">
        <Textarea v-model="pulse.newText" rows="3" class="w-full" auto-resize/>
        <div class="flex justify-end mt-2 gap-2">
          <Button label="Save" icon="pi pi-check" @click="save" :loading="saving"/>
          <Button label="Cancel" icon="pi pi-times" @click="cancel" severity="secondary" outlined/>
        </div>
      </div>
    </div>
  </Fieldset>

</template>
